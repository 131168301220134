import { Route } from "react-router-dom"
import { MainLayout, PrivateRoute } from "./wrappers"
import { lazy } from "react"
import { lazyRetry } from "./utils"

const PeriodPlannerPage = lazy(() => lazyRetry(() => import("../views/PeriodPlanner/PeriodPlannerPage"), "periodPlanner"))
const Register = lazy(() => lazyRetry(() => import("../views/User/Register"), "register"))
const Home = lazy(() => lazyRetry(() => import("../views/Home"), "home"))
const Tests = lazy(() => lazyRetry(() => import("../views/Test/Tests"), "tests"))
const Test = lazy(() => lazyRetry(() => import("../views/Test/Test"), "test"))
const TestResult = lazy(() => lazyRetry(() => import("../views/Test/TestResult"), "testResult"))
const TestResultVisualization = lazy(() => lazyRetry(() => import("../views/Test/TestResultVisualization"), "testResultVisualization"))
const PlayerMatchPerformanceRating = lazy(() => lazyRetry(() => import("../views/Diary/MatchRatingCoach"), "playerMatchPerformanceRating"))
const PeerSurvey = lazy(() => lazyRetry(() => import("../views/Diary/PeerSurvey"), "peerSurvey"))
const InvitePatron = lazy(() => lazyRetry(() => import("../views/RootGroup/InvitePatron"), "invitePatron"))
const TestProtocols = lazy(() => lazyRetry(() => import("../views/Test/TestProtocols"), "testProtocols"))
const TestProtocol = lazy(() => lazyRetry(() => import("../views/Test/TestProtocol"), "testProtocol"))
const Event = lazy(() => lazyRetry(() => import("../views/Event/Event"), "event"))
const InviteMember = lazy(() => lazyRetry(() => import("../views/RootGroup/InviteMemberEmail"), "inviteMember"))
const ConfirmMemberInviteExisting = lazy(() => lazyRetry(() => import("../views/User/ConfirmMemberInviteExisting"), "confirmMemberInviteExisting"))
const RegistrationMember = lazy(() => lazyRetry(() => import("../views/User/RegistrationMember"), "registrationMember"))
const GroupList = lazy(() => lazyRetry(() => import("../views/Group/GroupList"), "groupList"))
const GroupCreate = lazy(() => lazyRetry(() => import("../views/Group/GroupCreate"), "groupCreate"))
const GroupDetail = lazy(() => lazyRetry(() => import("../views/Group/GroupDetail"), "groupDetail"))
const RootGroupDetail = lazy(() => lazyRetry(() => import("../views/RootGroup/RootGroupDetail"), "rootGroupDetail"))
const RootGroupCreate = lazy(() => lazyRetry(() => import("../views/RootGroup/RootGroupCreate"), "rootGroupCreate"))
const Profile = lazy(() => lazyRetry(() => import("../views/User/Profile"), "profile"))
const ChildAccountConfirmation = lazy(() => lazyRetry(() => import("../views/User/ChildAccountConfirmation"), "childAccountConfirmation"))
const EditMemberships = lazy(() => lazyRetry(() => import("../views/RootGroup/EditMemberships"), "editMemberships"))
const TestChartsPlayer = lazy(() => lazyRetry(() => import("../views/Charts/TestChartsPlayer"), "testChartsPlayer"))
const TestChartsGroup = lazy(() => lazyRetry(() => import("../views/Charts/TestChartsGroup"), "testChartsGroup"))
const GlobalSpecial = lazy(() => lazyRetry(() => import("../views/Test/GlobalSpecial"), "globalSpecial"))
const MatchRatingChartsGroup = lazy(() => lazyRetry(() => import("../views/Charts/MatchRatingChartsGroup"), "matchRatingChartsGroup"))
const MatchRatingChartsPlayer = lazy(() => lazyRetry(() => import("../views/Charts/MatchRatingChartsPlayer"), "matchRatingChartsPlayer"))
const ExerciseChart = lazy(() => lazyRetry(() => import("../views/Charts/ExerciseChart"), "exerciseChart"))
const ExerciseChartWeek = lazy(() => lazyRetry(() => import("../views/Charts/ExerciseChartWeek"), "exerciseChartWeek"))
const ExerciseChartDay = lazy(() => lazyRetry(() => import("../views/Charts/ExerciseChartDay"), "exerciseChartDay"))
const TaskOverview = lazy(() => lazyRetry(() => import("../views/Tasks/TaskOverview"), "taskOverview"))
const VideoRootGroup = lazy(() => lazyRetry(() => import("../views/Video/VideoRootGroup"), "VideoRootGroup"))
const PeriodPlannerEditOrCreate = lazy(() => lazyRetry(() => import("../views/PeriodPlanner/PeriodPlannerEditOrCreate"), "periodPlannerEditOrCreate"))
const LifeManagementCharts = lazy(() => lazyRetry(() => import("../components/Charts/CoachSurvey/LifeManagementCharts"), "lifeManagementCharts"))
const FFAClubSupportPlayerAssign = lazy(() => lazyRetry(() => import("../views/FFAClubSupport/FFAClubSupportPlayerAssign"), "ffaClubSupportPlayerAssign"))

// Flag for enabling conditional rendering that hides root group select inputs at 'managerial' pages and uses role selector root group context instead
const USE_ROOT_CTX = true

const MainRoute = <Route
	path="/"
	element={
		<PrivateRoute>
			<MainLayout fullWidth />
		</PrivateRoute>
	}
>
	<Route index element={<Home />} />
	<Route path="/charts/personal" element={<TestChartsPlayer />} />
	<Route path="/charts/exercise" element={<ExerciseChart />} />
	<Route path="/charts/group" element={<TestChartsGroup />} />
	<Route path="/charts/lifemanagement" element={<LifeManagementCharts />} />
	<Route path="/charts/matchratingGroup" element={<MatchRatingChartsGroup />} />
	<Route path="/charts/matchratingPlayer" element={<MatchRatingChartsPlayer />} />
	<Route path="/charts/exercise_week" element={<ExerciseChartWeek />} />
	<Route path="/charts/exercise_day" element={<ExerciseChartDay />} />
	<Route path="/test_results" element={<TestResultVisualization />} />
	<Route path="/tests/:testId" element={<Test />} />
	<Route path="/tests" element={<Tests />} />
	<Route path="/tests/globalSpecials/:id" element={<GlobalSpecial />} />
	<Route path="/testprotocols" element={<TestProtocols />} />
	<Route path="/testprotocols/:protocolId" element={<TestProtocol />} />
	<Route path="/events/:eventId" element={<Event />} />
	<Route path="/events/:eventId/tests/:testId" element={<TestResult />} />
	<Route path="/events/:eventId/player_match_performance" element={<PlayerMatchPerformanceRating />} />
	<Route path="/events/:eventId/peerSurvey" element={<PeerSurvey />} />
	<Route path="/invite_patron" element={<InvitePatron useRootGroupCtx={USE_ROOT_CTX} />} />
	<Route path="/register" element={<Register />} />
	<Route path="/registerchild" element={<RegistrationMember childcreation />} />
	<Route path="/invite_member" element={<InviteMember useRootGroupCtx={USE_ROOT_CTX} />} />
	<Route path="/groups" element={<GroupList useRootGroupCtx={USE_ROOT_CTX} />} />
	<Route path="/groups/create" element={<GroupCreate />} />
	<Route path="/groups/:id" element={<GroupDetail />} />
	<Route path="/groups/root/create" element={<RootGroupCreate />} />
	<Route path="/groups/root/:id" element={<RootGroupDetail />} />
	<Route path="/profile" element={<Profile own />} />
	<Route path="/profile/:id" element={<Profile />} />
	<Route path="/child_invite_confirm" element={<ConfirmMemberInviteExisting acceptChild />} />
	<Route path="/activatechild" element={<ChildAccountConfirmation />} />
	<Route path="/edit_memberships" element={<EditMemberships useRootGroupCtx={USE_ROOT_CTX} />} />
	<Route path="/tasks" element={<TaskOverview />} />
	<Route path="/video/rootGroup/:id" element={<VideoRootGroup />} />
	<Route path="/plans/period_planner" element={<PeriodPlannerPage />} />
	<Route path="/plans/period_planner/:id" element={<PeriodPlannerEditOrCreate />} />
	<Route path="/ffa-club-support/player-assign" element={<FFAClubSupportPlayerAssign />} />
</Route>

export default MainRoute