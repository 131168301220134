import { lazy } from "react"
import { lazyRetry } from "./utils"
import { MainLayout, PrivateRoute } from "./wrappers"
import { Route } from "react-router-dom"

const Help = lazy(() => lazyRetry(() => import("../views/Help/Help"), "help"))
const HelpNavigationPanel = lazy(() => lazyRetry(() => import("../components/Navigation/HelpNavigationPanel"), "helpNavigationPanel"))

const HelpRoute = <Route path='/help'
	element={
		<PrivateRoute>
			<MainLayout className="help-layout" rightPanel={<HelpNavigationPanel />} />
		</PrivateRoute>
	}>
	<Route path='/help' element={<Help />} />
</Route>

export default HelpRoute