import { convertObjToQueryStr } from "@utils/utils"
import { api } from "./api"
import store from "../store"
import _ from "lodash"

export const profileApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getProfile: builder.query({
			query: (profileId) => profileId 
				? ({url: `/profile/${profileId}`})
				: null,
			providesTags: (result, error, args) => {
				return [{ type: "Profile", id: args }, "Profile"]
			}
		}),
		// Paginated response; documents are in `docs` path
		searchProfile: builder.query({
			query: (queryObj) => ({
				url: `/profile/search${convertObjToQueryStr(queryObj)}`,
			}),
			providesTags: (result, error, args) => {
				return [{ type: "Profile", id: "LIST" }]
			}
		}),
		updateProfile: builder.mutation({
			query: ({ profileId, formData }) => ({
				url: `/profile/${profileId}`,
				method: "PUT",
				body: formData,
			}),
			invalidatesTags: (result, error, args) => {
				return [{ type: "Profile", id: args.profileId },]
			}
		}),
		anonymizeProfile: builder.mutation({
			query: (profileId) => ({
				url: `/profile/${profileId}/anonymize`,
				method: "POST",
			}),
			invalidatesTags: (result, error, args) => {
				return ["User"]
			}
		}),
		clearOneBulletin: builder.mutation({
			query: ({ profileId, bulletinId }) => ({
				url: `/profile/${profileId}/clearBulletins/${bulletinId}`,
				method: "POST"
			}),
			invalidatesTags: (result, error, args) => {
				return [{ type: "Profile", id: args.profileId },]
			}
		}),
		clearBulletins: builder.mutation({
			query: (profileId) => ({
				url: `/profile/${profileId}/clearBulletins`,
				method: "POST"
			}),
			invalidatesTags: (result, error, args) => {
				return [{ type: "Profile", id: args },]
			}
		}),
		updateOrderSettings: builder.mutation({
			query: ({ profileId, name, index }) => ({
				url: `/profile/${profileId}/ordering`,
				method: "PATCH",
				body: { name, index },
			}),
		}),
		updateContextSettings: builder.mutation({
			query: ({ profileId, body }) => ({
				url: `/profile/${profileId}/context`,
				method: "PATCH",
				body: body,
			}),
		}),
		createPersonalEvent: builder.mutation({
			query: ({ profileId, body }) => ({
				url: `/profile/${profileId}/event`,
				method: "POST",
				body: body,
			}),
			invalidatesTags: (result, error, args) => {
				return ["Event"]
			}
		}),
		updatePersonalEvent: builder.mutation({
			query: ({ profileId, eventId, body }) => ({
				url: `/profile/${profileId}/event/${eventId}`,
				method: "PUT",
				body: body,
			}),
			invalidatesTags: (result, error, args) => {
				return ["Event"]
			}
		}),
		getSettings: builder.query({
			query: () => "/profile/settings",
			providesTags: ["Preferences"]
		}),
		setSettings: builder.mutation({
			query: (body) => ({
				url: "/profile/settings",
				method: "PATCH",
				body,
			}),
			invalidatesTags: ["Preferences"],
			// Automatically update profile data cache context path via pessimistic update
			// Specifically waits for query resolution to avoid timing issues with stale `profile.settings.context` in backend.
			async onQueryStarted(body, { dispatch, queryFulfilled }) {
				if (!body.context) {
					return
				}

				const profile = store.getState().user.profile
				const childId = store.getState().user.childId
				const curProfile = childId || profile

				try {
					const { data: settingsData } = await queryFulfilled
					const patchResult = dispatch(
						api.util.updateQueryData("getProfile", curProfile, (draft) => {
							_.set(draft, "settings.context", settingsData.context)
							return draft
						})
					)
				} catch (e) {
					console.error(e)
				}
			},
		}),
		inviteNewParent: builder.mutation({
			query: ({ profileId, body }) => ({
				url: `/profile/${profileId}/inviteNewParent`,
				method: "POST",
				body,
			}),
			invalidatesTags: (result, error, args) => [{ type: "Profile", id: args.profileId }],
		}),
		cancelInviteNewParent: builder.mutation({
			query: ({ profileId, body }) => ({
				url: `/profile/${profileId}/inviteNewParent/cancel`,
				method: "POST",
				body,
			}),
			invalidatesTags: (result, error, args) => [{ type: "Profile", id: args.profileId }],
		}),
		acceptNewParentBulletin: builder.mutation({
			query: ({ profileId, bulletinId }) => ({
				url: `/profile/${profileId}/inviteNewParent/accept/${bulletinId}`,
				method: "POST",
			}),
			invalidatesTags: (result, error, args) => [{ type: "Profile", id: args.profileId }],
		}),
		declineNewParentBulletin: builder.mutation({
			query: ({ profileId, bulletinId }) => ({
				url: `/profile/${profileId}/inviteNewParent/decline/${bulletinId}`,
				method: "POST",
			}),
			invalidatesTags: (result, error, args) => [{ type: "Profile", id: args.profileId }],
		}),
		removeParentLink: builder.mutation({
			query: (parentProfileId) => ({
				url: `/profile/${parentProfileId}/removeParentLink`,
				method: "DELETE",
			}),
			invalidatesTags: (result, error, args) => [{ type: "Profile", id: args }, { type: "Profile", id: store.getState().user.profile }],
		})
	})
})

export const {
	useGetProfileQuery,
	useLazyGetProfileQuery,
	useSearchProfileQuery,
	useLazySearchProfileQuery,
	useUpdateProfileMutation,
	useAnonymizeProfileMutation,
	useClearOneBulletinMutation,
	useClearBulletinsMutation,
	useUpdateOrderSettingsMutation,
	useUpdateContextSettingsMutation,
	useCreatePersonalEventMutation,
	useUpdatePersonalEventMutation,
	useGetSettingsQuery,
	useSetSettingsMutation,
	useInviteNewParentMutation,
	useCancelInviteNewParentMutation,
	useAcceptNewParentBulletinMutation,
	useDeclineNewParentBulletinMutation,
	useRemoveParentLinkMutation
} = profileApi
