// Unauthenticated routes
import { lazy } from "react"
import { Route } from "react-router-dom"
import { lazyRetry } from "./utils"
import { LoginLayout } from "./wrappers"

const SetNewPassword = lazy(() => lazyRetry(() => import("../views/Auth/SetNewPassword"), "setNewPassword"))
const Login = lazy(() => lazyRetry(() => import("../views/Auth/Login"), "login"))
const ResetPassword = lazy(() => lazyRetry(() => import("../views/Auth/ResetPassword"), "resetPassword"))
const RegistrationPatron = lazy(() => lazyRetry(() => import("../views/User/RegistrationPatron"), "registrationPatron"))
const PersonRegister = lazy(() => lazyRetry(() => import("../views/User/PersonRegister"), "personRegister"))
const ConfirmPatronInviteExisting = lazy(() => lazyRetry(() => import("../views/User/ConfirmPatronInviteExisting"), "confirmPatronInviteExisting"))
const ConfirmMemberInviteExisting = lazy(() => lazyRetry(() => import("../views/User/ConfirmMemberInviteExisting"), "confirmMemberInviteExisting"))
const ConfirmRemovePassive = lazy(() => lazyRetry(() => import("../views/User/ConfirmRemovePassive"), "confirmRemovePassive"))
const RegistrationMember = lazy(() => lazyRetry(() => import("../views/User/RegistrationMember"), "registrationMember"))
const RegistrationNewParent = lazy(() => lazyRetry(() => import("../views/User/RegistrationNewParent"), "registrationNewParent"))

const UnauthenticatedRoute = <Route element={<LoginLayout />}>
	<Route path="/login" element={<Login />} />
	<Route path="/reset-password" element={<ResetPassword />} />
	<Route path="/reset-password/:id" element={<SetNewPassword />} />
	<Route path="/personregister" element={<PersonRegister />} />
	<Route path="/member_invite_registration/:id" element={<RegistrationMember />} />
	<Route path="/member_invite_confirm/:id" element={<ConfirmMemberInviteExisting />} />
	<Route path="/patron_invite_registration/:id" element={<RegistrationPatron />} />
	<Route path="/patron_invite_confirm/:id" element={<ConfirmPatronInviteExisting />} />
	<Route path="/remove_passive/:id" element={<ConfirmRemovePassive />} />
	<Route path="/new_parent_registration/:id" element={<RegistrationNewParent />} />
</Route>

export default UnauthenticatedRoute