import toast from "react-hot-toast"
import { Navigate, Route } from "react-router-dom"

const ErrorComponent = ({ ...attrs }) => {
	toast.error("Tapahtui odottamaton virhe. Järjestelmän ylläpitäjälle on ilmoitettu automaattisesti.")
	return <Navigate to="/" />
}

const ErrorRoute = <Route path="/error" element={<ErrorComponent />} />


export default ErrorRoute