/**
 * Markdown related redux state.
 */

import { createSlice } from "@reduxjs/toolkit"

export const markdownSlice = createSlice({
	name: "markdown",
	initialState: {
		current: {},
		previous: {}
	},
	reducers: {
		setMarkdown(state, {payload}) {
			state.current[payload.id] = payload.markdown
		},
		setPreviousMarkdown(state, {payload}) {
			state.previous[payload.id] = payload.markdown
		},
	},
})

export const {
	setMarkdown,
	setPreviousMarkdown,
} = markdownSlice.actions

export const markdownSelector = id => state => state.markdown.current[id]
export const previousMarkdownSelector = id => state => state.markdown.previous[id]