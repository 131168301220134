/**
 * Redux slice for maintaining status messages that can be inferred from various API responses.
 */

import { createSlice } from "@reduxjs/toolkit"

export const statusSlice = createSlice({
	name: "statusMessage",
	initialState: {
		openSurveyTasks: false
	},
	reducers: {
		setOpenSurveyTasks(state, {payload}) {
			state.openSurveyTasks = payload
		},
	},
})
 
export const {
	setOpenSurveyTasks
} = statusSlice.actions
 
export const statusMessageSelector = (state) => state.statusMessage