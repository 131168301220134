import { lazy } from "react"
import { Route } from "react-router-dom"
import { lazyRetry } from "./utils"
import { MainLayout, PrivateRoute } from "./wrappers"
import LegendPanel from "@components/Calendar/LegendPanel"

const CalendarNew = lazy(() => lazyRetry(() => import("../views/Event/CalendarNew"), "calendarNew"))

const CalendarRoute = <Route path='/calendar'
	element={
		<PrivateRoute>
			<MainLayout className="calendar-layout" rightPanel={<LegendPanel />} />
		</PrivateRoute>
	}>
	<Route path='/calendar' element={<CalendarNew />} />
</Route>

export default CalendarRoute