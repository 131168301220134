/**
 * Miscellaneous UI-related redux state.
 * 
 * Sometimes used for general actions and state
 * when setting up prop inheritance is cumbersome.
 */

import { createSlice } from "@reduxjs/toolkit"

export const uiSlice = createSlice({
	name: "ui",
	initialState: {
		confirmModal: {},
		leftPanelVisible: false,
		modalIsActive: false, // Whether a modal is being currently shown
		testResultMergeToggle: true,
		eventDetailEditDisabled: false,
		memberCategoryTab: null,
		disableRoleSelect: false,
		eventForceReload: false, // Forces refetch of event modal; needed due to video upload not invalidating tags properly due to in-flight requests
	},
	reducers: {
		toggleLeftPanel(state) {
			state.leftPanelVisible = !state.leftPanelVisible
		},
		setConfirmModal(state, {payload}) {
			if ( !("show" in payload) ) {
				payload.show = true
			}
			state.confirmModal = payload
		},
		setConfirmModalVisibility(state, {payload}) {
			state.confirmModal.show = payload
		},
		setModalIsActive(state, {payload}) {
			state.modalIsActive = payload
		},
		setTestResultMergeToggle(state, {payload}) {
			state.testResultMergeToggle = payload
		},
		setEventDetailEditDisable(state, {payload}) {
			state.eventDetailEditDisabled = payload
		},
		setMemberCategoryTab(state, {payload}) {
			state.memberCategoryTab = payload
		},
		setDisableRoleSelect(state, {payload}) {
			state.disableRoleSelect = payload
		},
		setEventForceReload(state, {payload}) {
			state.eventForceReload = payload
		}
	},
})

export const {
	setConfirmModal,
	setConfirmModalVisibility,
	toggleLeftPanel,
	setModalIsActive,
	setTestResultMergeToggle,
	setEventDetailEditDisable,
	setMemberCategoryTab,
	setDisableRoleSelect,
	setEventForceReload
} = uiSlice.actions

export const uiSelector = (state) => state.ui
export const confirmModalSelector = state => state.ui.confirmModal
export const leftPanelVisibilitySelector = state => state.ui.leftPanelVisible