import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import { 
	settingsSelector, 
	setCalendarEventTypeFilter,
	toggleCalendarEventTypeFilter 
} from "@redux/settings"
import _ from "lodash"
import { useOperationalClass } from "@utils/hooks"
import Icon from "@components/Common/Icon"
import {useEventColors, useGetCombinedEventTypes} from "@utils/customization/customization"

const LegendPanel = () => {
	const { getColor } = useEventColors()
	const { selectedCalendar, calendarEventTypeFilter } = useSelector(settingsSelector)
	const { t }  = useTranslation()

	const dispatch = useDispatch()

	const opClass = useOperationalClass()
	const { groupEventTypes, personalEventTypes } = useGetCombinedEventTypes() ?? {}
	const isPersonal = (selectedCalendar === "own" || selectedCalendar === undefined)

	const curEventTypes = !opClass ? [] : (!isPersonal ? groupEventTypes.filter(t => !t.hideCalendarLegend) : personalEventTypes.concat(groupEventTypes).filter(t => t.name !== "other" && !t.hideCalendarLegend))

	const legendArr = useMemo(() => {
		return curEventTypes.map(type => ({ name: type.name, title: type.title, color: getColor(type.name) }))
	}, [curEventTypes])

	if (!opClass)
		return null

	const nonAvailableTypes = (calendarEventTypeFilter || [])
		.filter(e => !curEventTypes.find(_e => e === _e.name) )
	if ( nonAvailableTypes.length ) {
		const types = calendarEventTypeFilter.filter(
			e => !nonAvailableTypes.includes(e)
		)
		dispatch(setCalendarEventTypeFilter(types)) 
	}

	return (
		<>
			<div className="d-none d-xl-block">
				<h1 style={{ visibility: "hidden" }}>-</h1>
				<div style={{ height: 24 }}></div>
			</div>
			<h6 style={{ marginBottom: 0 }}>{t("Tapahtumatyypit")}</h6>
			<div style={{ fontSize: ".8em", color: "#888", marginBottom: 5 }}>
				{t("Voit suodattaa kalenterissa näkyviä tapahtumia klikkaamalla tyyppiä.")}
				<div className="d-block d-xl-none" style={{ height: 4 }}></div>
			</div>
			<div className="d-none d-xl-block" style={{ height: 8 }}></div>
			<div className="d-flex d-xl-block flex-wrap calendar-legend">
				{legendArr.map(item => {
					const filterEnabled = calendarEventTypeFilter?.find(e => e === item.name)
					return <div className="d-flex" style={{ marginRight: 25, alignItems: "center", lineHeight: "40px", cursor: "pointer" }} key={item.name} onClick={() => {
						dispatch(toggleCalendarEventTypeFilter(item.name))
					}}>
						<div className={"d-flex calendar-legend-item " + (filterEnabled ? "calendar-legend-item-selected" : "")}>
							<div style={{ backgroundColor: item.color, minWidth: 15, minHeight: 15, width: 15, height: 15, borderRadius: "50%", marginRight: 20 }}></div>
							<span className="ms-1 me-2" style={{ fontSize: 15, flex: 1, lineHeight: "18px", paddingTop: 4, paddingBottom: 4, wordBreak: "break-word" }}>
								{item.title}
							</span>
							<div className="d-block d-xl-none" style={{ width: 8 }}></div>
							<Icon name="close" style={{ visibility: filterEnabled ? "visible" : "hidden", fontWeight: 400 }} />
						</div>
					</div>
				})}
			</div>
		</>
	)
}

export default LegendPanel