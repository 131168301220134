import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import store from "@/store"

axios.interceptors.request.use(function (config) {
	const token = store.getState().user.token
	config.headers["X-Access-Token"] = token

	return config
})

export const register = createAsyncThunk("user/register", async (registerCredentials, thunkAPI) => {
	try {
		let email = registerCredentials.email
		let roles = registerCredentials.roles
		let username = registerCredentials.username
		let profile = { ...registerCredentials }
		delete profile.roles
		delete profile.email
		delete profile.username
		let body = { email, username, profile, roles }
		const response = await axios.post("http://127.0.0.1:3001/api/users", body)
		if (response.status === 200) {
			return response.data
		} else {
			return thunkAPI.rejectWithValue(response.data)
		}
	} catch (error) {
		return thunkAPI.rejectWithValue(error.response.data)
	}
})

export const registerSlice = createSlice({
	name: "register",
	initialState: {
		username: "",
		firstname: "",
		lastname: "",
		birthday: "",
		date: "",
		phone: "",
		palloid: "",
		sporttiId: "",
		email: "",
		sex: "",
		isSuccess: false,
		isError: false,
		isFetching: false,
	},
	reducers: {},
	extraReducers: {
		[register.fulfilled]: (state, { payload }) => {
			return {
				...payload,
				username: `${payload.username}`,
				firstname: `${payload.firstname}`,
				lastname: `${payload.lastname}`,
				roles: `${payload.roles}`,
				birthday: `${payload.date}`,
				date: `${payload.date}`,
				phone: `${payload.phone}`,
				palloid: `${payload.palloid}`,
				sporttiId: `${payload.sporttiId}`,
				email: `${payload.email}`,
				sex: `${payload.sex}`,
				isSuccess: true,
				isFetching: false,
				isError: false,
			}
		},
		[register.rejected]: (state) => {
			state.isSuccess = false
			state.isFetching = false
			state.isError = true
		},
		[register.pending]: (state) => {
			state.isSuccess = false
			state.isFetching = true
			state.isError = false
		},
	},
})
export const registerSelector = (state) => state.register
