import { Route } from "react-router-dom"
import { MainLayout, PrivateRoute, WellbeingWrapper } from "./wrappers"
import { lazy } from "react"
import { lazyRetry } from "./utils"
// Wellbeing-restricted routes

const WellbeingEvent = lazy(() => lazyRetry(() => import("../views/Event/WellbeingEvent"), "wellbeingEvent"))
const WellbeingExerciseChart = lazy(() => lazyRetry(() => import("../views/Charts/WellbeingExerciseChart"), "WellbeingExerciseChart"))
const WellbeingTestChartsGroup = lazy(() => lazyRetry(() => import("../views/Charts/WellbeingTestChartsGroup"), "wellbeingTestChartsGroup"))
const WellbeingTestChartsPersonal = lazy(() => lazyRetry(() => import("../views/Charts/WellbeingTestChartsPersonal"), "wellbeingTestChartsPersonal"))

const WellbeingRoute = <Route
	element={
		<PrivateRoute>
			<WellbeingWrapper>
				<MainLayout fullWidth />
			</WellbeingWrapper>
		</PrivateRoute>
	}
>
	<Route path="/wb/events/:eventId" element={<WellbeingEvent />} />
	<Route path="/wb/charts/exercise" element={<WellbeingExerciseChart />} />
	<Route path="/wb/charts/group" element={<WellbeingTestChartsGroup />} />
	<Route path="/wb/charts/personal" element={<WellbeingTestChartsPersonal />} />
</Route>

export default WellbeingRoute