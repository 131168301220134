import { api } from "./api"
import _ from "lodash"

export const rootGroupClassApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getCustomerGroups: builder.query({
			query: () => ({
				url: "/customerGroup",
			}),
			providesTags: (result, error, args) => {
				if (error)
					return []
				return result.map(doc => ({ type: "CustomerGroup", id: doc._id })).concat([{type: "CustomerGroup", id: "LIST"}])
			}
		}),
		getCustomerGroup: builder.query({
			query: (id) => ({
				url: `/customerGroup/${id}`,
			}),
			providesTags: (result, error, arg) => {
				return [{ type: "CustomerGroup", id: arg }]
			}
		}),
		createCustomerGroup: builder.mutation({
			query: (body) => ({
				url: "/customerGroup",
				method: "POST",
				body
			}),
			invalidatesTags: (result, error, args) => {
				return [{ type: "CustomerGroup", id: "LIST" }]
			}
		}),
		updateCustomerGroup: builder.mutation({
			query: ({id, body}) => ({
				url: `/customerGroup/${id}`,
				method: "PUT",
				body
			}),
			invalidatesTags: (result, error, args) => {
				return [{ type: "CustomerGroup", id: "LIST" }, { type: "CustomerGroup", id: args.id }]
			}
		}),
		removeCustomerGroup: builder.mutation({
			query: (id) => ({
				url: `/customerGroup/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: (result, error, args) => {
				return [{ type: "CustomerGroup", id: "LIST" }]
			}
		}),
		getRootGroupClasses: builder.query({
			query: () => ({
				url: "/rootGroupClass",
			}),
			providesTags: (result, error, args) => {
				if (error)
					return []
				return result.map(doc => ({ type: "RootGroupClass", id: doc._id })).concat([{type: "RootGroupClass", id: "LIST"}])
			}
		}),
		getRootGroupClass: builder.query({
			query: (id) => ({
				url: `/rootGroupClass/${id}`,
			}),
			providesTags: (result, error, arg) => {
				return [{ type: "RootGroupClass", id: arg }]
			}
		}),
		createRootGroupClass: builder.mutation({
			query: (body) => ({
				url: "/rootGroupClass",
				method: "POST",
				body
			}),
			invalidatesTags: (result, error, args) => {
				return [{ type: "RootGroupClass", id: "LIST" }]
			}
		}),
		updateRootGroupClass: builder.mutation({
			query: ({id, body}) => ({
				url: `/rootGroupClass/${id}`,
				method: "PUT",
				body
			}),
			invalidatesTags: (result, error, args) => {
				return [{ type: "RootGroupClass", id: "LIST" }, { type: "CustomerGroup", id: "LIST" }, { type: "RootGroupClass", id: args.id }]
			}
		}),
		removeRootGroupClass: builder.mutation({
			query: (id) => ({
				url: `/rootGroupClass/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: (result, error, args) => {
				return [{ type: "RootGroupClass", id: "LIST" }, { type: "CustomerGroup", id: "LIST" }]
			}
		}),
		getDictionaries: builder.query({
			query: () => ({
				url: "/rootGroupClass/dictionaries",
			}),
			providesTags: (result, error, args) => {
				if (error)
					return []
				return _.keys(result).map(id => ({ type: "RootGroupClass", id })).concat([{ type: "RootGroupClass", id: "LIST" }])
			}
		}),
	})
})

export const {
	useGetCustomerGroupsQuery,
	useGetCustomerGroupQuery,
	useCreateCustomerGroupMutation,
	useUpdateCustomerGroupMutation,
	useRemoveCustomerGroupMutation,
	useGetRootGroupClassesQuery,
	useGetRootGroupClassQuery,
	useCreateRootGroupClassMutation,
	useUpdateRootGroupClassMutation,
	useRemoveRootGroupClassMutation,
	useGetDictionariesQuery,
	useLazyGetDictionariesQuery
} = rootGroupClassApi
