import { lazy } from "react"
import { lazyRetry } from "./utils"
import { Route } from "react-router-dom"
import { MainLayout, PrivateRoute } from "./wrappers"


const VideoPlayer = lazy(() => lazyRetry(() => import("../views/VideoPlayer/VideoPlayer"), "videoPlayer"))
const VideoPlayerTab = lazy(() => lazyRetry(() => import("../components/VideoPlayer/VideoPlayerTab"), "videoPlayerTab"))


const VideoPlayerRoute = <Route path='/videos/:videoId'
	element={
		<PrivateRoute>
			<MainLayout
				className="video-layout"
				maximizeContent={true}
				tab={<VideoPlayerTab />}
			/>
		</PrivateRoute>
	}>
	<Route path='/videos/:videoId' element={<VideoPlayer />} />
</Route>

export default VideoPlayerRoute