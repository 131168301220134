/**
 * Confirm modal template that accepts props.
 * 
 * Using this component instead of `confirmModal` redux state allows
 * dynamic content due to re-rendering being restricted in redux dispatch.
 */

import { Button, Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Icon from "@components/Common/Icon"

const ConfirmModalTemplate = ({ show, onHide, title, body, variant, noDim, okFn, okLabel, cancelLabel }) => {
	const { t } = useTranslation()

	const _variant = variant || "default"

	const ICON = {
		default: "help_outline",
		info: "info_outline",
		danger: "highlight_off"
	}

	return <Modal
		show={show}
		centered
		onHide={onHide}
		className={"confirm-modal " + _variant}
		backdropClassName={noDim ? "transparent" : undefined}
	>
		<Modal.Header closeButton>
		</Modal.Header>
		<Modal.Body>
			<div className="mt-4 mb-3 confirm-modal-title">
				<div className="icon mb-3">{ICON[_variant] || ICON.default}</div>
				<div className="confirm-modal-title-text">{title}</div>
			</div>
			<div className="confirm-modal-body">
				{body}
			</div>
		</Modal.Body>
		<Modal.Footer className="mb-5 p-0">
			<Button style={{ marginRight: 10 }} variant={_variant === "danger" ? _variant : "primary"} onClick={() => {
				okFn()
				onHide()
			}}>
				{okLabel || t("Kyllä")}
			</Button>
			<Button variant="gray" onClick={onHide} className="cancel">
				<Icon name="highlight_off" />
				<div style={{ marginLeft: 10 }}>{cancelLabel || t("Peruuta")}</div>
			</Button>
		</Modal.Footer>
	</Modal>
}

export default ConfirmModalTemplate