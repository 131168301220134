import _ from "lodash"
import { fi } from "date-fns/locale"

export const VERSION = process.env.REACT_APP_VERSION || "1.2.0beta"
export const MULTILANGUAGE_SUPPORT_ENABLED = !!process.env.REACT_APP_MULTILANGUAGE_SUPPORT_ENABLED
const USE_SAME_DOMAIN = !!process.env.REACT_APP_USE_SAME_DOMAIN
let domain = window.location.protocol + "//" + window.location.hostname + ":3000"
export const NEW_ID = "_new"
export const BACKEND_URL = USE_SAME_DOMAIN ? domain : process.env.REACT_APP_BACKEND_URL || "https://myeway.fi/back-end"
export const IMAGE_URL = USE_SAME_DOMAIN ? domain + "/uploads/images" : process.env.REACT_APP_IMAGE_URL || (BACKEND_URL + "/uploads/images")
export const SHOW_GROUP_ROLE_COLLAPSIBLE = !!process.env.REACT_APP_SHOW_GROUP_ROLE_COLLAPSIBLE
export const SHOW_DEV_ONLY_COMPONENTS = !!process.env.REACT_APP_SHOW_DEV_ONLY_COMPONENTS
export const SHOW_DEV_DISCLAIMER = !!process.env.REACT_APP_SHOW_DEV_DISCLAIMER
export const MD_URL = (BACKEND_URL + "/uploads/md")

// Static timezone used for date display and entry instead of device-dependent locale
export const TZ = "Europe/Helsinki"

export const MAINTENANCE_MESSAGE = process.env.REACT_APP_MAINTENANCE_MESSAGE
export const MAINTENANCE_BANNER_VARIANT = process.env.REACT_APP_MAINTENANCE_BANNER_VARIANT
export const MAINTENANCE_USE_FILE = !!process.env.REACT_APP_MAINTENANCE_USE_FILE

export const IS_DEV = process.env.REACT_APP_ENVIRONMENT !== "production" && (process.env.REACT_APP_ENVIRONMENT === "development" || process.env.NODE_ENV === "development")
export const IS_PROD = !IS_DEV
if (IS_DEV)
	console.log("Running development build")
if (IS_PROD)
	console.log("Running production build")


// Customized finnish locale object that uses colon in time values
let localeCopy = _.cloneDeep(fi)
const timeFormats = {
	full: "HH:mm:ss zzzz",
	long: "HH:mm:ss z",
	medium: "HH:mm:ss",
	short: "HH:mm",
}
_.set(localeCopy, "formatLong.time", (opts) => {
	return timeFormats[opts?.width ?? "full"]
})
export const fiColons = localeCopy


/**
 * Thumbnail filenames follow convention `{size}_{identifier}.{extension}`.
 * Size 1000 has jpg extension, others have png
 **/ 
export const THUMBNAIL_SIZES = [50, 50, 150, 250, 250, 1000]

export const TRANSLATIONS = {
	"antropometry": "Antropometria",
	"physical": "Fyysinen",
	"sport_skill": "Lajitekniikka",
	"knowledge": "Tieto",
	"general": "Yleinen",
	"muscle_balance": "Lihastasapaino ja liikkuvuus",
	"football": "Jalkapallo",
	"floorball": "Salibandy",
	"team_match": "Ottelu",
	"team_training": "Harjoitus",
	"group_test": "Testi",
	"team_tournament": "Turnaus",
	"team_other": "Muu tapahtuma",
	"team_camp": "Leiri",
	"team_target": "Tavoite",
	"team_period_target": "Jaksotavoite",
	"team_year_target": "Vuositavoite",
	"player": "Pelaaja",
	"basic_user": "Peruskäyttäjä",
	"advanced_user": "Yläkäyttäjä",
	"coach": "Valmentaja",
	"headcoach": "Valmennuspäällikkö",
	"parent": "Huoltaja",
	"admin": "Ylläpitäjä",
	"patron": "Pääkäyttäjä"
}

export const EVENT_TYPE_COLOR_OPACITY = "aa"

export const EVENT_TYPE_COLORS = {
	"default": "#020202",
	"team_match": "#983000",
	"team_training": "#FE5000",
	"group_test": "#FE9666",
	"team_tournament": "#1D4F91",
	"team_other": "#7795BD",
	"other": "#7795BD",
	"team_camp": "#D2DCE9",
	"team_target": "#FFDCCC",
	"team_year_target": "#FFDCCC",
	"team_period_target": "#FFDCCC",
	"training": "#3366ff",
	"absent": "#666699",
	"injury": "#000066",
	"sleep": "#00ccff",
	"survey": "#660066",
	"meeting": "#117733",
	"nutrition": "#0000ff",
	"fba_spring": "#660066",
	"fba_autumn": "#660066",
	"fba_tournament": "#660066",
	"fba_international": "#660066",
	"sha_spring": "#660066",
	"sha_autumn": "#660066",
	"sha_tournament": "#660066",
	"sha_international": "#660066",
	"floorball_national": "#660066",
	"football_national": "#660066",
	"floorball_player_path": "#660066",
	"spl_regional_spring": "#660066",
	"spl_regional_autumn": "#660066"
}

export const EXCEL_INSTRUCTIONS = {
	DATA_ENTRY_TITLE_EN: "Filling out test results",
	DATA_ENTRY_EN: `
- Test results are entered in the 'Results' sheet, selected from below tabs. 'Extra information' tab allows editing test-specific values. Other sheets do not need to be edited.
- Extra rows can be added as long as the identifying information matches with an existing user. New rows create new event participants when uploaded to the test result page.
  * Note that entering decimal numbers requires using the decimal separator defined in Excel settings. Using a wrong separator may cause the value to be autofilled as a date.
- PalloID / SporttiID is optional, but it's recommended; specify this when there are non-unique first and last names among the test participants.
- Out-of-bounds values are highlighted in red and correct values are highlighted in green.
- When result entry is finished, save the file with the .xlsx extension. Microsoft Excel does this by default.
    `,
	DATA_ENTRY_TITLE_FI: "Testitulosten täyttäminen",
	DATA_ENTRY_FI: `
- Testitulokset täytetään 'Tulokset' välilehdessä, jonka voi valita ikkunan alaosassa. 'Lisämääreet' välilehdessä voi muuttaa muita testikohtaisia tietoja. Muihin välilehtiin ei tarvitse tehdä muutoksia.
  * Huomioi että desimaaliarvoja syöttäessä on käytettävä Excel:in asetuksissa määritettyä desimaalierotinta. Väärällä merkillä arvo voi automaattisesti muuttua päivämääräksi.
- Ylimääräisiä rivejä voi lisätä, kunhan uuden rivin tunnistetiedot osuvat olemassaolevaan käyttäjään. Uudet rivit luovat lisäosallistujia tapahtumaan kun tiedosto ladataan testitulossivustolle.
- PalloID / SporttiID kenttä ei ole pakollinen, mutta on suositeltava; täytä se mikäli testin osallistujilla on sama etu- ja sukunimi.
- Minimi- ja maksimirajat ylittävät arvot korostuvat punaisella automaattisesti, oikeat arvot korostuvat vihreällä.
- Kun tulokset ovat valmiita, tallenna tiedosto .xlsx tiedostopäättellä. Microsoft Excel tekee tämän oletuksena.
    `,
}

/**
 * Colors sourced from
 * 
 * https://personal.sron.nl/~pault/
 */

// Muted qualitative colour scheme
export const LINE_CHART_COLORS = [
	"#332288",
	"#88ccee",
	"#44aa99",
	"#117733",
	"#999933",
	"#ddcc77",
	"#cc6677",
	"#882255",
	"#aa4499"
]

// Bright qualitative colour scheme
export const LINE_CHART_COLORS_ALT = [
	"#4477aa",
	"#66ccee",
	"#228833",
	"#ccbb44",
	"#ee6677",
	"#aa3377",
]

export const GRAPH_CHANGE_COLORS = [
	"#002365",
	"#00369a",
	"#0047cb",
	"#11d8d1",
	"#cb87ff",
	"#ae46ff",
	"#fab84a"
]

export const COHEN_D_COLORS = [
	"#18dcd4",
	"#082464",
	"#fcbc4c",
	"#04349c",
	"#ac44fc",
	"#0444cc",
	"#cc84fc",
	"#4484fc",
	"#dcb4fc",
]

export const STATISTIC_AVERAGES_FI = {
	"best": "Paras tulos",
	"top5": "Parhaat 5 keskiarvo",
	"top10": "Parhaat 10 keskiarvo",
	"teamAverage": "Joukkueen keskiarvo",
}

export const AGGREGATES_FI = {
	"average": "Keskiarvo",
	"best": "Paras tulos",
	"maximum": "Maksimi",
	"minimum": "Minimi",
	"median": "Mediaani",
}

export const GENERAL_EVENT_DIARY_TYPES = [
	"speed",
	"strength",
	"endurance",
	"movability",
	"other"
]

export const FOOTBALL_EVENT_DIARY_TYPES = ["own_football", "own_football_position", "own_football_small_games", "own_football_skill", "guided_football",]

export const FLOORBALL_EVENT_DIARY_TYPES = ["own_floorball_skill", "own_floorball_small_games", "guided_floorball"]

export const PERSONAL_EVENT_TYPES = [
	"training", "other",
]

export const GROUP_EVENT_TYPES = [
	"team_match",
	"team_training",
	"group_test",
	"meeting",
	"team_tournament",
	"team_camp",
	"team_other",
	"survey"
]

export const EVENTS_WITH_SURFACE_TYPE = [
	"training",
	"team_match",
	"team_training",
	"team_other",
]

export const TASK_TYPES = ["player_match_performance_rating", "test_results", "test_results_protocol"]
export const TASK_TYPES_WITH_RESULT_ENTRY = ["player_match_performance_rating", "test_results", "test_results_protocol"]

export const ACTIONABLE_BULLETIN_TYPES = [
	"invite",
	"invite-child-when-non-parent",
	"invite-own-child",
	"invite-other-child",
	"invite-register-other-child",
	"invite-register-child-when-non-parent",
	"activate-child",
	"add_new_parent",
	"confirm-invite",
	"confirm-invite-child",
	"membership-removal-request"
]

export const UNDEFINED_STRING = "-"

export const ADULT_ONLY_GROUP_ROLES = ["headcoach"]

export const MEMBER_LIMIT_PRESET = [50, 150, 400, 1000, 2000]

// Used in ChartsGroup to control visible tests
export const TEST_CATEGORIES_FOOTBALL = [
	{
		name: "Nopeus",
		items: [
			"Nopeus 5m",
			"Nopeus 10m",
			"Nopeus 20m",
			"Nopeus 30m",
			"Kasirata",
			"SM-juoksu (jalkapallo)",
			"SM-pallo (jalkapallo)"
		]
	}, {
		name: "Nopeusvoima",
		items: [
			"Staattinen hyppy",
			"Kevennyshyppy",
			"Kevennyshyppy kuormilla (15kg/20kg/25%BW)",
			"Kevennyshyppy kuormilla (30kg/40kg/50%BW)",
			"Elastisuus",
			"Vauhditon pituushyppy",
			"5-loikka"
		]
	}, {
		name: "Maksimi- ja kestovoima",
		items: [
			"Etunojapunnerrus",
			"Leuanvedot",
			{
				name: "Pistoolikyykky",
				nameOverride: "Pistoolikyykky - Vasen",
				resultFieldIndex: 0
			},
			{
				name: "Pistoolikyykky",
				nameOverride: "Pistoolikyykky - Oikea",
				resultFieldIndex: 1
			},
			"Hyppynaruhypyt"
		]
	}, {
		name: "Kestävyys",
		items: [
			{
				name: "1200m (MAS)",
				nameOverride: "1200 (MAS) m/s",
				specialFieldIndex: 0
			},
			{
				name: "1200m (MAS)",
				nameOverride: "1200 (MAS) km/h",
				specialFieldIndex: 1
			},
			{
				name: "1200m (MAS)",
				timeTotal: true
			}
		]
	}, {
		name: "Lajitekniikka",
		items: [
			"Pujottelu",
			"Syöttö",
			"Ponnauttelu"
		]
	}, {
		name: "Liikkuvuus ja tasapaino",
		items: [
			{
				name: "Tasapaino",
				nameOverride: "Tasapaino - Vasen",
				resultFieldIndex: 0
			},
			{
				name: "Tasapaino",
				nameOverride: "Tasapaino - Oikea",
				resultFieldIndex: 1
			},
			{
				name: "Nilkan liikkuvuus",
				nameOverride: "Nilkan liikkuvuus - Vasen",
				resultFieldIndex: 0
			},
			{
				name: "Nilkan liikkuvuus",
				nameOverride: "Nilkan liikkuvuus - Oikea",
				resultFieldIndex: 1
			},
			"Eteentaivutus haaraistunnassa",
			{
				name: "Lonkankoukistajien liikkuvuus (Thomasin testi)",
				nameOverride: "Lonkankoukistajien liikkuvuus (Thomasin testi) - Vasen",
				resultFieldIndex: 0
			},
			{
				name: "Lonkankoukistajien liikkuvuus (Thomasin testi)",
				nameOverride: "Lonkankoukistajien liikkuvuus (Thomasin testi) - Oikea",
				resultFieldIndex: 1
			},
			"Valakyykky"
		]
	}
]

export const TEST_CATEGORIES_FOOTBALL_PLAYER = [
	{
		name: "Nopeus",
		items: [
			"Nopeus 5m",
			"Nopeus 10m",
			"Nopeus 20m",
			"Nopeus 30m",
			"Kasirata",
			"SM-juoksu (jalkapallo)",
			"SM-pallo (jalkapallo)"
		]
	}, {
		name: "Nopeusvoima",
		items: [
			"Staattinen hyppy",
			"Kevennyshyppy",
			"Kevennyshyppy kuormilla (15kg/20kg/25%BW)",
			"Kevennyshyppy kuormilla (30kg/40kg/50%BW)",
			"Vauhditon pituushyppy",
			"5-loikka"
		]
	}, {
		name: "Maksimi- ja kestovoima",
		items: [
			"Etunojapunnerrus",
			"Leuanvedot",
			{
				name: "Pistoolikyykky",
				nameOverride: "Pistoolikyykky - Vasen",
				resultFieldIndex: 0
			},
			{
				name: "Pistoolikyykky",
				nameOverride: "Pistoolikyykky - Oikea",
				resultFieldIndex: 1
			},
			"Naruhypyt 60s",
			"Trapbar maastaveto",
			"Päkiälle nousu",
			"Varpaat tankoon",
			"Kylkipito 90 s",
		]
	}, {
		name: "Kestävyys",
		items: [
			{
				name: "1200m (MAS)",
				nameOverride: "1200 (MAS) m/s",
				specialFieldIndex: 0
			},
			{
				name: "1200m (MAS)",
				nameOverride: "1200 (MAS) km/h",
				specialFieldIndex: 1
			},
			{
				name: "1200m (MAS)",
				timeTotal: true
			}
		]
	}, {
		name: "Lajitekniikka",
		items: [
			"Pujottelu",
			"Syöttö",
			"Ponnauttelu"
		]
	},{
		name: "Liikkuvuus ja tasapaino",
		items: [
			{
				name: "Tasapaino",
				nameOverride: "Tasapaino - Vasen",
				resultFieldIndex: 0
			},
			{
				name: "Tasapaino",
				nameOverride: "Tasapaino - Oikea",
				resultFieldIndex: 1
			},
			{
				name: "Nilkan liikkuvuus",
				nameOverride: "Nilkan liikkuvuus - Vasen",
				resultFieldIndex: 0
			},
			{
				name: "Nilkan liikkuvuus",
				nameOverride: "Nilkan liikkuvuus - Oikea",
				resultFieldIndex: 1
			},
			"Eteentaivutus haaraistunnassa",
			{
				name: "Lonkankoukistajien liikkuvuus (Thomasin testi)",
				nameOverride: "Lonkankoukistajien liikkuvuus (Thomasin testi) - Vasen",
				resultFieldIndex: 0
			},
			{
				name: "Lonkankoukistajien liikkuvuus (Thomasin testi)",
				nameOverride: "Lonkankoukistajien liikkuvuus (Thomasin testi) - Oikea",
				resultFieldIndex: 1
			},
			"Valakyykky"
		]
	}
]

// Used in ChartsGroup to control visible tests
export const TEST_CATEGORIES_FLOORBALL = [
	{
		name: "Nopeus",
		items: [
			"Nopeus 5m",
			"Nopeus 10m",
			"Nopeus 20m",
			"Nopeus 30m",
			"Kasirata",
			"SM-juoksu (salibandy)",
			"SM-pallo (salibandy)"
		]
	}, {
		name: "Nopeusvoima",
		items: [
			"Staattinen hyppy",
			"Kevennyshyppy",
			"Kevennyshyppy kuormilla (15kg/20kg/25%BW)",
			"Kevennyshyppy kuormilla (30kg/40kg/50%BW)",
			"Vauhditon pituushyppy",
			"5-loikka"
		]
	}, {
		name: "Maksimi- ja kestovoima",
		items: [
			{
				name: "Lihaskunto K2",
				nameOverride: "Lihaskunto K2 - Toistomaksimi",
				specialFieldIndex: 0
			}
		]
	}, {
		name: "Kestävyys",
		items: [
			{
				name: "Nopeuskestävyys (10x20m, 15 ́ ́)",
				nameOverride: "Nopeuskestävyys (10x20m, 15 ́ ́) - Keskiarvo",
				specialFieldIndex: 0,
			},
			{
				name: "Nopeuskestävyys (10x20m, 15 ́ ́)",
				nameOverride: "Nopeuskestävyys (10x20m, 15 ́ ́) - Nopein",
				specialFieldIndex: 1,
			},
			{
				name: "Nopeuskestävyys (10x20m, 15 ́ ́)",
				nameOverride: "Nopeuskestävyys (10x20m, 15 ́ ́) - Hitain",
				specialFieldIndex: 2,
			},
			{
				name: "Nopeuskestävyys (10x20m, 15 ́ ́)",
				nameOverride: "Nopeuskestävyys (10x20m, 15 ́ ́) - Väsyminen",
				specialFieldIndex: 3,
			},
			{
				name: "Piip-testi",
				timeTotal: true
			},
			{
				name: "Palautumissyke 1min.",
				nameOverride: "Palautumissyke 1min. - Suhteellinen palautumissyke",
				specialFieldIndex: 0
			},
			{
				name: "Palautumissyke 1min.",
				nameOverride: "Palautumissyke 1min. - Palautumissyke %",
				specialFieldIndex: 1
			},
			{
			 	name: "Palautumissyke 1min.",
				nameOverride: "Palautumissyke -abs",
				resultFieldIndex: 1,
			},
			{
				name: "Submax. Piip-testi 6min.",
				nameOverride: "Keskisyke",
				resultFieldIndex: 0,
			},
			{
				name: "Maksimisyke",
				nameOverride: "Keskisyke -%",
				resultFieldIndex: 0
			}
		]
	}, {
		name: "Lajitekniikka",
		items: [
			"Pallonhallinta"
		]
	}, {
		name: "Liikkuvuus ja tasapaino",
		items: [
			{
				name: "Tasapaino",
				nameOverride: "Tasapaino - Vasen",
				resultFieldIndex: 0
			},
			{
				name: "Tasapaino",
				nameOverride: "Tasapaino - Oikea",
				resultFieldIndex: 1
			},
			{
				name: "Nilkan liikkuvuus",
				nameOverride: "Nilkan liikkuvuus - Vasen",
				resultFieldIndex: 0
			},
			{
				name: "Nilkan liikkuvuus",
				nameOverride: "Nilkan liikkuvuus - Oikea",
				resultFieldIndex: 1
			},
			"Eteentaivutus haaraistunnassa",
			{
				name: "Lonkankoukistajien liikkuvuus (Thomasin testi)",
				nameOverride: "Lonkankoukistajien liikkuvuus (Thomasin testi) - Vasen",
				resultFieldIndex: 0
			},
			{
				name: "Lonkankoukistajien liikkuvuus (Thomasin testi)",
				nameOverride: "Lonkankoukistajien liikkuvuus (Thomasin testi) - Oikea",
				resultFieldIndex: 1
			},
		]
	}
]

export const TEST_CATEGORIES_FLOORBALL_PLAYER = [
	{
		name: "Nopeus",
		items: [
			"Nopeus 5m",
			"Nopeus 10m",
			"Nopeus 20m",
			"Nopeus 30m",
			"Kasirata",
			"SM-juoksu (salibandy)",
			"SM-pallo (salibandy)"
		]
	}, {
		name: "Nopeusvoima",
		items: [
			"Staattinen hyppy",
			"Kevennyshyppy",
			"Kevennyshyppy kuormilla (15kg/20kg/25%BW)",
			"Kevennyshyppy kuormilla (30kg/40kg/50%BW)",
			"Vauhditon pituushyppy",
			"5-loikka"
		]
	}, {
		name: "Maksimi- ja kestovoima",
		items: [
			{
				name: "Lihaskunto K2",
				nameOverride: "Lihaskunto K2 - Toistomaksimi",
				specialFieldIndex: 0
			}
		]
	}, {
		name: "Kestävyys",
		items: [
			{
				name: "Nopeuskestävyys (10x20m, 15 ́ ́)",
				nameOverride: "Nopeuskestävyys (10x20m, 15 ́ ́) - Keskiarvo",
				specialFieldIndex: 0,
			},
			{
				name: "Nopeuskestävyys (10x20m, 15 ́ ́)",
				nameOverride: "Nopeuskestävyys (10x20m, 15 ́ ́) - Nopein",
				specialFieldIndex: 1,
			},
			{
				name: "Nopeuskestävyys (10x20m, 15 ́ ́)",
				nameOverride: "Nopeuskestävyys (10x20m, 15 ́ ́) - Hitain",
				specialFieldIndex: 2,
			},
			{
				name: "Nopeuskestävyys (10x20m, 15 ́ ́)",
				nameOverride: "Nopeuskestävyys (10x20m, 15 ́ ́) - Väsyminen",
				specialFieldIndex: 3,
			},
			{
				name: "Piip-testi",
				timeTotal: true
			},
			{
				name: "Palautumissyke 1min.",
				nameOverride: "Palautumissyke 1min. - Suhteellinen palautumissyke",
				specialFieldIndex: 0
			},
			{
				name: "Palautumissyke 1min.",
				nameOverride: "Palautumissyke 1min. - Palautumissyke %",
				specialFieldIndex: 1
			}
		]
	}, {
		name: "Lajitekniikka",
		items: [
			"Pallonhallinta"
		]
	}, {
		name: "Liikkuvuus ja tasapaino",
		items: [
			{
				name: "Tasapaino",
				nameOverride: "Tasapaino - Vasen",
				resultFieldIndex: 0
			},
			{
				name: "Tasapaino",
				nameOverride: "Tasapaino - Oikea",
				resultFieldIndex: 1
			},
			{
				name: "Nilkan liikkuvuus",
				nameOverride: "Nilkan liikkuvuus - Vasen",
				resultFieldIndex: 0
			},
			{
				name: "Nilkan liikkuvuus",
				nameOverride: "Nilkan liikkuvuus - Oikea",
				resultFieldIndex: 1
			},
			"Eteentaivutus haaraistunnassa",
			{
				name: "Lonkankoukistajien liikkuvuus (Thomasin testi)",
				nameOverride: "Lonkankoukistajien liikkuvuus (Thomasin testi) - Vasen",
				resultFieldIndex: 0
			},
			{
				name: "Lonkankoukistajien liikkuvuus (Thomasin testi)",
				nameOverride: "Lonkankoukistajien liikkuvuus (Thomasin testi) - Oikea",
				resultFieldIndex: 1
			},
		]
	}
]

// Basic role order by priority
export const ROLE_ORDER = [
	"admin",
	"headcoach",
	"coach",
	"hyvinvointi_valmentaja",
	"manager",
	"player",
	"hyvinvointi_osallistuja",
	"none"
]

export const RANK_DESCRIPTIONS = {
	5: "ominaisuus on kansainvälisellä kärkitasolla",
	4: "ominaisuus on kansallisella kärkitasolla",
	3: "ominaisuus on hieman yli kansallisen keskitason",
	2: "ominaisuus on hieman alle kansallisen keskitason",
	1: "ominaisuus on alle kansallisen keskitason"
}

export const RANK_DESCRIPTIONS_1_TO_3 = {
	3: "ominaisuus on kansainvälisellä kärkitasolla",
	2: "ominaisuus on kansallisella keskitasolla",
	1: "ominaisuus on alle kansallisen keskitason"
}