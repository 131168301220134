import { formatInTimeZone as  _formatInTimeZone, OptionsWithTZ } from "date-fns-tz"
import { fi } from "date-fns/locale"

/**
 * Wrapper around `date-fns-tz/formatInTimeZone`, overriden to have `options` always contain a `fi`-locale.
 * 
 * Should be used over `date-fns-tz/formatInTimeZone`.
 * @param {Date | string | number} date 
 * @param {string} timeZone 
 * @param {string} formatStr 
 * @param {OptionsWithTz?} options - Options to use to override overriden options.
 * @returns {string}
 */

export const formatInTimeZone = (date, timeZone, formatStr, options = undefined) => _formatInTimeZone(date, timeZone, formatStr, options ?? { locale: fi })
