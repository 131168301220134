/**
 * Embeddable component that disables the role selector. Reverts disabled state on unmount.
 */

import { setDisableRoleSelect } from "@redux/ui"
import { useEffect } from "react"
import { useDispatch } from "react-redux"

const DisableRoleSelect = () => {

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(setDisableRoleSelect(true))
		return () => dispatch(setDisableRoleSelect(false))
	}, [])

	return null
}

export default DisableRoleSelect