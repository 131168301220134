import { useState } from "react"
import { Alert } from "react-bootstrap"

const Banner = ({ children, ...rest }) => {

	const [show, setShow] = useState(true)

	return (
		<>
			{show && <Alert onClose={() => setShow(false)} {...rest}>
				{children ?? "TEXT"}
			</Alert>}
		</>
	)
}

export default Banner