import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { userSelector, logoutUser } from "../redux/user"
import { BACKEND_URL } from "@/const"
import toast from "react-hot-toast"

const baseQueryWithAuth = fetchBaseQuery({
	baseUrl: `${BACKEND_URL}/api`,
	prepareHeaders: (headers, { getState }) => {
		const { token, childId } = userSelector(getState())
		if (token)
			headers.set("X-Access-Token", token)
		if (childId)
    	headers.set("x-child-id", childId)
		return headers
	},
	responseHandler: "content-type"
})

const baseQuery = async (args, api, extraOptions) => {
	let result = await baseQueryWithAuth(args, api, extraOptions)
	const { error } = result
	if (error && error.status === 401 && error.data && (error.data.message === "Token Expired" || error.data.message === "No valid session" || error.data.message === "Could not parse access token")) {
		toast.error("Kirjaudu sisään uudelleen", {
			id: "login-again"
		})
		api.dispatch(logoutUser())
		console.warn("Token expired or missing. This may also be caused by incorrect URL env-values accessing a non-existent route.")
		// Skip any further query logic by returning undefined
		return undefined
	}
	return result
}

export const api = createApi({
	reducerPath: "api",
	tagTypes: [
		"User",
		"Profile",
		"Training",
		"Location",
		"TrainingResult",
		"EventList",
		"Event",
		"TestProtocol",
		"Participant",
		"Group",
		"RootGroup",
		"TemplateRole",
		"GlobalSpecial",
		"Preferences",
		"RootGroupClass",
		"Diary",
		"Survey",
		"SurveyType",
		"TaskDefinition",
		"HelpFile",
		"ConsentTermsFile",
		"PrivacyPolicyFile",
		"CustomerGroup",
		"Notification",
		"Video",
		"PeriodPlanner",
		"PeriodPlannerTemplate",
		"EventType",
		"Debug"
	],
	baseQuery: baseQuery,
	endpoints: () => ({}),
})
