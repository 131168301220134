import { combineReducers } from "redux"
import { configureStore } from "@reduxjs/toolkit"
import storage from "redux-persist/lib/storage"
import { persistReducer, purgeStoredState } from "redux-persist"
import { userSlice } from "./redux/user"
import { uiSlice } from "./redux/ui"
import { settingsSlice } from "./redux/settings"
import { draftSlice } from "./redux/draft"
import { api } from "./api/api"
import { visualizationSlice } from "./redux/visualization"
import { registerSlice } from "./redux/register"
import { markdownSlice } from "./redux/markdown"
import { statusSlice } from "@redux/statusMessage"
import {createStateSyncMiddleware, initMessageListener} from "redux-state-sync"

const persistConfig = {
	key: "root",
	storage,
	whitelist: ["user", "draft"],
	blacklist: ["settings"]
}

const reduxStateSyncConfig = {
	whitelist: ["user/logoutUser", "user/login/fulfilled"],
}

const settingsPersistConfig = {
	key: "settings",
	storage,
	blacklist: ["selectedDate", "calendarInitialized", "selectedCalendar"],
}

const reducers = combineReducers({
	user: userSlice.reducer,
	register: registerSlice.reducer,
	ui: uiSlice.reducer,
	settings: persistReducer(settingsPersistConfig, settingsSlice.reducer),
	draft: draftSlice.reducer,
	visualization: visualizationSlice.reducer,
	markdown: markdownSlice.reducer,
	statusMessage: statusSlice.reducer,
	[api.reducerPath]: api.reducer
})

const rootReducer = (state, action) => {
	if ( action.type === "FATAL_ERROR" ) {
		purgeStoredState(persistConfig)
		state = undefined
		window.location = "/error"
	}
	else if (action.type === userSlice.actions.logoutUser.type) {
		// Setting state to undefined here applies initial state to all reducers
		state = undefined
	}
	return reducers(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}).concat(createStateSyncMiddleware(reduxStateSyncConfig)).concat(api.middleware),
})

initMessageListener(store)

export default store
