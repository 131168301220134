import {
	confirmModalSelector,
	setConfirmModalVisibility
} from "@redux/ui"
import { useDispatch, useSelector } from "react-redux"
import ConfirmModalTemplate from "./ConfirmModalTemplate"

const ConfirmModal = () => {
	const confirmModal = useSelector(confirmModalSelector)
	const dispatch = useDispatch()

	const handleClose = () => {
		dispatch(setConfirmModalVisibility(false))
		if ( confirmModal.cancelFn ) {
			confirmModal.cancelFn()
		}
	}

	return <ConfirmModalTemplate 
		{...confirmModal}
		onHide={handleClose}
	/>
}

export default ConfirmModal