import { createSlice } from "@reduxjs/toolkit"
import _ from "lodash"

export const draftSlice = createSlice({
	name: "draft",
	initialState: {
		testResults: [],
		playerMatchPerformanceRatings: [],
		inviteMembersToRootGroup: {}
	},
	reducers: {
		addTestResultsDraft(state, { payload }) {
			let index = state.testResults.findIndex((item) => {
				return (
					item.eventId === payload.eventId && 
					item.profileId === payload.profileId && 
					item.taskId === payload.taskId
				)
			})
			if (index !== -1)
				state.testResults[index] = payload
			else
				state.testResults.push(payload)
		},
		removeTestResultsDraftForProfile(state, { payload }) {
			const { eventId, profileId, targetProfile, taskId } = payload
			let obj = state.testResults.find((item) => {
				return (
					item.eventId === eventId && 
					item.profileId === profileId && 
					item.taskId === taskId
				)
			})
			if (obj?.results) {
				// Structure can be either `results[testId][profileId]` or `results[profileId]`
				Object.keys(obj.results).forEach(key => {
					const item = obj.results[key]
					if (_.isObject(item)) {
						Object.keys(item).forEach(key2 => {
							if (key2 === targetProfile){
								delete item[key2]
							}
						})
					} else {
						if (key === targetProfile) {
							delete obj.results[key]
						}
					}
				})
			}
		},
		addPlayerMatchPerformanceRatingsDraft(state, { payload }) {
			let index = state.playerMatchPerformanceRatings.findIndex((item) => {
				return (item.eventId === payload.eventId && item.profileId === payload.profileId)
			})
			if (index !== -1)
				state.playerMatchPerformanceRatings[index] = payload
			else
				state.playerMatchPerformanceRatings.push(payload)
		},
		removePlayerMatchPerformanceRatingsDraft(state, { payload }) {
			state.playerMatchPerformanceRatings = state.playerMatchPerformanceRatings.filter(
				rating => rating.eventId !== payload.eventId && rating.profileId !== payload.profileId
			)
		},
		addInviteMembersToRootGroup(state, { payload }) {
			state.inviteMembersToRootGroup[payload.rootGroupId] = payload.invites
		},
		removeInviteMembersToRootGroup(state, { payload }) {
			delete state.inviteMembersToRootGroup[payload]
		},
	},
})

export const { 
	addTestResultsDraft,
	removeTestResultsDraftForProfile,
	addPlayerMatchPerformanceRatingsDraft,
	removePlayerMatchPerformanceRatingsDraft,
	addInviteMembersToRootGroup,
	removeInviteMembersToRootGroup
} = draftSlice.actions
export const draftSelector = state => state.draft